/* CMS Master */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Satisfy&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
body {
  font-family: Lato, sans-serif;
}

p,
a,
span,
label,
input,
tr,
td,
button,
textarea,
select,
b,
small {
  font-family: Lato, sans-serif;
  /* color: #414141; */
}

.form_border {
  border: 1px solid #a74343;
  border-radius: 20px;
}

.form-check-input:checked {
  background-color: #a74343;
  border-color: #a74343;
  box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25);
}

.form-control:focus {
  border-color: #a74343;
  box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25);
}

.form-select:focus {
  border-color: #a74343;
  box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25);
}

select option:hover {
  background-color: #a74343;
  color: #fff;
}

.heading_color {
  color: #444;
}

.form_border div>p {
  font-size: 14px;
}

.location_border {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.table_border {
  border: 1px solid #a74343;
}

.Status_button i {
  color: #a74343;
}

.Status_button {
  background-color: transparent !important;
  padding: 0px 0px;
  border: transparent;
}

.Status_button:focus,
.Status_button:hover {
  box-shadow: none !important;
  transition: all 0.8s;
  background-color: transparent !important;
}

.Status_button:focus i,
.Status_button:hover i {
  color: #fcc101 !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

thead button.ascending::after {
  content: '\21E9';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: ' \21E7';
  display: inline-block;
  margin-left: 1em;
}


.table-hover tbody tr:hover td {
  background-color: lightyellow;
}


.select_dropdown {
  font-size: 14px;
}

.css-1pahdxg-control,
.css-ve73ta-control {
  border-color: #a74343 !important;
  box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25) !important;
}

/* .css-b62m3t-container {
  font-family: 'Poppins', sans-serif;
} */

.table-scrollable {
  overflow-x: auto;
  margin: auto;
}

.rdt_TableCell {
  color: #414141 !important;
  font-size: 15px !important;
}

.rdt_TableCol {
  font-size: 1rem !important;
}

.gnaqej:focus {
  border: 1px solid #a74343;
}

.bvxQGL:hover:not(:disabled) {
  background-color: #a74343 !important;
}

.bvxQGL:hover:not(:disabled) svg {
  fill: #fff;
}

.forgotpsw {
  color: blue;
}

.forgotpsw:hover {
  color: #a74343;
}

/* .clearable-input {
  position: relative;
  display: inline-block;
}

.clearable-input>input {
  padding-right: 1.4em;
}

.clearable-input>[data-clear-input] {a
  display: none;
  position: absolute;
  top: 4px;
  right: 0;
  font-weight: bold;
  font-size: 1.4em;
  padding: 0 0.2em;
  line-height: 1em;
  cursor: pointer;
}

.clearable-input>input::-ms-clear {
  display: none;
} */

/* .fa-file-pdf-o,
.fa-file-excel-o {
  cursor: pointer;
} */

.bi-star-fill {
  color: #fcc101 !important;
}

/* responsive */

@media(max-width:991px) {
  .iieSkk {
    min-width: 36% !important;
  }

  .gCxdUn {
    min-width: 20% !important;
  }

  .kDRFRh {
    min-width: 20% !important;
  }

  .iWutVk {
    min-width: 5% !important;
  }
}

.required::after {
  content: "*";
  color: red;
}

.btn_add::-webkit-file-upload-button {
  display: none;
}

/* package master According*/
.accordion-button:not(.collapsed) {
  color: #a74343;
  background-color: #ffe7e7d1;
  /* box-shadow: inset 0 0 0 0.2rem rgba(167, 40, 40, 0.25); */
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border: 1px solid #a74343;
  border-color: 0 0 0 0.2rem rgba(167, 40, 40, 0.25);
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25); */
  box-shadow: none;
}

.border_set {
  border: 1px solid #a74343;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a74343'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a74343'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-body {
  background-color: #ffe7e73d;
  border-left: 1px solid #a74343;
  border-right: 1px solid #a74343;
  border-bottom: 1px solid #a74343;
}

/* .accordion-item{
  border-color: #f6c6c6;
} */
.accordion-button {
  background-color: #ffe7e73d;
  border: 1px solid #a74343;
}

/* .accordion-button:not(.collapsed)::after .accordion-header{
  border-color: #a74343;
} */
/* pack css */
section.package-detail .service-icon ul {
  padding: 0;
  margin: 0;
  text-align: right;
}

section.package-detail .service-icon li {
  background: #a74343;
  /* color: #fff; */
  margin: 0 5px;
  padding: 14px 15px;
  border-radius: 50%;
  line-height: 0;
}

section.package-detail .service-icon i {
  color: #fff;
  font-size: 24px;
}

section.package-detail .package-inner {
  border: 1px solid #ccc;
}

i.fa.fa-hand-lizard-o {
  transform: rotate(103deg);
  color: #9d9a9a;
  font-size: 14px;
}

span.border_top {
  width: 50px;
  height: 1px;
  background: #a5a3a3;
  display: block;
}

.flight1 li {
  text-align: center;
  font-size: 15px;
}

.date p {
  line-height: 1;
  font-size: 13px;
}

.hetel-box,
.itinerary-box,
.Inclusions-box,
.exclusions-box {
  margin: 5px;
  padding: 10px;
  background: #f2f2f2;
}

span.fs-10.mb-2 {
  font-size: 11px;
}

.fs-8 {
  font-size: 14px;
}

.hotel1 span.border_top {
  width: 30px;
}

.hotel-des {
  max-width: 150px;
}

.hotel1 i.fa.fa-calendar {
  color: #fcc101;
}

div#summery li {
  font-size: 12px;
  padding: 0 10px;
}

span.border_top {
  width: 15px;
}

.summery-title {
  color: #000;
  font-weight: 500;
  margin-left: 10px;
}

.booking-summery.mt-5 {
  padding: 20px;
  background: #efeded;
  max-height: 300px;
  overflow-y: scroll;
}

.booking-summery button {
  border: none;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  background: #efeded;
  width: 100%;
  text-align: left;
  position: sticky;
  top: -20px;
  padding: 10px;
}

div#summery1 {
  display: none;
}

.container-fluid .d-flex {
  background: none !important;
}

/* .bnAwAJ{
  max-width: 170px ;
} */
/* .fa-edit,
.fa-eye {
  cursor: pointer;
} */

.rdt_TableRow>div:nth-child(n-3) {
  /* float: left; */
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
}

.rdt_TableHeadRow>div:nth-child(n-3) {
  /* float: left; */
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
}

.bnAwAJ:nth-last-child(-n + 2) {
  justify-content: center;
}

.rdt_TableRow>div:nth-last-child(1) {
  right: 0;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  padding-left: 1px;
  padding-right: 1px;
  justify-content: center;
}

.rdt_TableHeadRow>div:nth-last-child(1) {
  right: 0;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  padding-left: 1px;
  padding-right: 1px;
  justify-content: center;
}

.rdt_TableRow>div:nth-last-child(2) {
  right: 44px;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
}

.rdt_TableHeadRow>div:nth-last-child(2) {
  right: 44px;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
}

.rdt_TableRow:hover>div:nth-last-child(1) {
  background-color: #EEEEEE;
}

.rdt_TableRow:hover>div:nth-last-child(2) {
  background-color: #EEEEEE;
}

.defualt-unstyled ul {
  list-style-type: initial;
}

.defualt-unstyled li {
  list-style: inherit;
}

.defualt-unstyled td {
  border-width: 1px !important;
}

.wraptextcont {
  position: relative;
  /* word-break: break-all; */
  text-align: justify;
}

.inputgroupclr {
  background-color: transparent;
  border-right: none;
}

.prefix_div {
  position: relative;
}

.prefix {
  position: absolute;
  transform: translate(60%, 22%);
  /* display: flex; */
}

.cutoffinput {
  padding-left: 2rem;
}

@media (max-width: 991px) {
  .d_responsive {
    height: 100% !important;
    width: 50% !important;
  }
}

@media (max-width: 767px) {
  .d_responsive {
    width: 140% !important;
    height: 100% !important;
  }

  .day1-box ul {
    padding-left: 0;
  }
}

.img_thumb {
  width: 100%;
  object-fit: fill;
  height: 100px;
}

.cityimage {
  width: 200px;
  height: 130px;
}

.img_thumbhotel {
  width: 100%;
  object-fit: fill;
  height: 70px;
}

.video_thumb {
  height: 70px !important;
}

.closebtnimg {
  position: absolute;
  transform: translate(0%, -30%);
  padding: 0;
}

.clrbutton {
  position: relative;
}

.clrbutton>input {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}

.clrbutton>input:placeholder-shown+button {
  opacity: 0;
  pointer-events: none;
}

.clrbutton>button {
  position: absolute;
  border: none;
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 15px;
  border-radius: 50%;
  right: 0;
  transform: translate(-75%, -160%);
  background: #ddd;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: .1s;
}

@media (max-width: 600px) {

  .table_scroll {
    overflow-x: scroll;
  }
}

/* tab section supplier start*/
.tab_section ul li a.active {
  /* background-color: #a74343 !important; */
  background-color: #a74343 !important;
  color: #fff !important;
  border-bottom: 3px solid #fcc101;
}

.tab_section ul li a {
  background-color: #e9ecef !important;
  color: #a74343;
}

.tab_section ul li a:hover {
  color: #e68a8a;
}

/* tab section supplier end */

.mobileprefix {
  position: absolute;
  font-size: 14px;
  transform: translate(60%, 36%);
  color: #6c757d !important;

}

.mobilecutoffinput {
  padding-left: 2.5rem !important;
}

/* InventoryManagement css start */
.tableborder_color {
  border-color: #a74343 !important;
}

.tableheaderborder_color {
  border-bottom: 2px solid #a74343 !important;
}

.inventorycolor {
  color: #a74343;
}

.tabspan_width {
  width: 30px;
  height: 30px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  margin: auto;
}

.inventory_headermodal {
  background-color: #a74343 !important;
  padding: .25rem 1rem .3rem 1rem;
  margin-top: 0;
}

.inventory_headermodal .inventory_title {
  color: #fff;
  text-align: center;
}

.inventory_headermodal .closebtn_position {
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0.5rem 0.5rem !important;
  opacity: 0.9 !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.over_table {
  overflow-x: scroll;
}

.table_body th {
  min-width: 175px;
}

.tableheaderborder_color th:first-child {
  min-width: 175px;
}

.tableheaderborder_color th {
  text-wrap: nowrap;
}

.bg_available {
  background-color: #24a40999 !important;
}

.bg_SoldOut {
  background-color: #ff572282 !important;
}

.bg_OnRequest {
  background-color: #edda328f !important;
}

.bg_BlackOut {
  background-color: #ff0000bf !important;
}

.spancolor_size {
  height: 13px;
  width: 13px;
}

.filter_section {
  display: flex;
  align-items: center;
  background-color: #EEEEEE;
  padding: 5px;
  border-radius: 50px;
  font-size: 13px;
}

.filter_buttonclose {
  color: white !important;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter_section:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.filter_section:hover .filter_buttonclose {
  transform: scale(1.1);
  /* font-size: 16px; */
  font-size: 1.06rem;
}

/* InventoryManagement css end */
/* User Admin css start */
.user_role .rdt_TableRow>div:nth-last-child(2) {
  right: auto !important;
  float: left !important;
  position: unset;
  background-color: transparent;
  min-width: auto !important;
  max-width: 100% !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
}

.user_role .rdt_TableHeadRow>div:nth-last-child(2) {
  right: auto !important;
  float: left;
  position: unset;
  background-color: #fff;
  min-width: auto !important;
  max-width: 100% !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
}

.admin_btn i {
  font-size: 18px;
  color: #122d59;
  transition: all 0.4s ease;
}

.rotet {
  transform: rotateZ(-180deg);
}

/* User Admin css end */
/* API Config start */
.modify_button button {
  padding: 0.25rem 0.75rem;
}

/* API Config end */
.set_disableclr:disabled {
  opacity: 0.55;
}

/* Intracity css start */
.intacity_border.active {
  border-color: #a74343 !important;
  border-bottom-color: #fff !important;
}

/* Intracity css end */

/* Booking Transaction List table CSS start */

/* Name column */
.booking_transaction_table .rdt_TableRow>div:nth-child(1) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 300px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(1) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 300px !important;
}

/* Name column */


/* MobileNo column */
.booking_transaction_table .rdt_TableRow>div:nth-child(2) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(2) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

/* MobileNo column */


/* Eamil column */
.booking_transaction_table .rdt_TableRow>div:nth-child(3) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 325px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(3) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 325px !important;
}

/* Email column */


/* PackageCode column */
.booking_transaction_table .rdt_TableRow>div:nth-child(4) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(4) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

/* PackageCode column */


/* BookingId column */
.booking_transaction_table .rdt_TableRow>div:nth-child(5) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(5) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

/* BookingId column */


/* Amount column */
.booking_transaction_table .rdt_TableRow>div:nth-child(6) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 120px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(6) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 120px !important;
}

/* Amount column */


/* PaymentMode column */
.booking_transaction_table .rdt_TableRow>div:nth-child(7) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(7) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

/* PaymentMode column */


/* PaymentStatus column */
.booking_transaction_table .rdt_TableRow>div:nth-child(8) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(8) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 150px !important;
}

/* PaymentStatus column */


/* TransactionId column */
.booking_transaction_table .rdt_TableRow>div:nth-child(9) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 330px !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(9) {
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  min-width: 330px !important;
}

/* TransactionId column */


/* TransactionDate column */
.booking_transaction_table .rdt_TableRow>div:nth-child(10) {
  position: unset;
  min-width: 150px !important;
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
  right: auto !important;
  justify-content: start !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(10) {
  position: unset;
  min-width: 150px !important;
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
  justify-content: start !important;
}

/* TransactionDate column */


/* ResponseMessage column */
.booking_transaction_table .rdt_TableRow>div:nth-child(11) {
  position: unset;
  min-width: 1000px !important;
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  justify-content: start !important;
}

.booking_transaction_table .rdt_TableHeadRow>div:nth-child(11) {
  position: unset;
  min-width: 1000px !important;
  float: left !important;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100% !important;
  justify-content: start !important;
}

/* ResponseMessage column */

/* Booking Transaction List table CSS end */

.remove_hover_effect {
  color: unset !important;
}

/* Trip planner standard service css start */

.standard_service_margin {
  margin-top: 22px;
}

/* Trip planner standard service css end */

/* Job application List Table css start */

.job_application .rdt_TableRow>div:nth-last-child(2) {
  position: unset;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
  justify-content: left;
}

.job_application .rdt_TableHeadRow>div:nth-last-child(2) {
  position: unset;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
}

.job_application a {
  text-decoration: none !important;
  color: blue !important;
}

.job_application a.remove_hover_effect {
  text-decoration: none !important;
  color: unset !important;
}

/* Job application List Table css end */

/* Job application View css start */

.preview-link a {
  color: blue;
}

.preview-link {
  color: blue;
}

/* Job application View css end */