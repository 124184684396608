/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,900&display=swap');



/* h1,
h2,
h3,
h4,
h5,
h6,
body {
  font-family: 'Playfair Display', serif;
} */

p,
a,
span,
label,
input,
tr,
td,
button,
textarea,
select,
b,
small {
  /* font-family: 'Poppins', sans-serif; */
  color: #414141;
}

@font-face {

  src: url("../fonts/9a7c0b474bb379f1a844d96411a9268f.woff");
  src: url("../fonts/9a7c0b474bb379f1a844d96411a9268f.woff2");
  font-style: normal;
  font-family: "Belymon Script DEMO";
}

/* root Variable */
:root {
  /* color */
  --dark_red: #a74343;
  --white: #fff;
  --dark_yellow: #fcc101;
  --dark_black: #000;

  /* font_family */
  --text_primary: 'Poppins', sans-serif;
  --text_secondry: 'Playfair Display', serif;
  --sp_text: "Belymon Script DEMO";
  --text_footer: 'Poppins';
}

/* end of: Font */

/* comman css */
.text-primary {
  color: var(--dark_red) !important;
}

a:hover {
  color: var(--dark_red);
}

.text-secondary {
  color: var(--dark_yellow) !important;
}

body {
  background-color: var(--white);
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: blue;
}

.btn-primary {
  color: var(--white);
  background-color: var(--dark_red) !important;
  border-color: var(--dark_red) !important;
}

.btn-primary:hover {
  background-color: var(--dark_yellow) !important;
  border-color: var(--dark_yellow) !important;
}

.fs-6 {
  font-size: 0.7rem !important;
}

/* nav */
section.top-header {
  background: var(--white);
  position: relative;
  z-index: 999;
}

.language-text li,
.download ul {
  display: inline-flex;
  vertical-align: middle;

}

.language-text li {
  padding: 0 5px;
}

.brand ul p {
  margin: 0;
  font-size: 12px;
}

.brand ul h6 {
  margin: 0;
  font-family: var(--text_primary);
  color: var(--dark_black);
  font-size: 14px;
}

.brand li {
  vertical-align: middle;
}

.container-fluid {
  max-width: 1797px;
  margin: 0 auto;
}

.brand.d-flex {
  width: 60%;
}

.container-fluid .d-flex {
  align-items: center;
  background: var(--white);
}

.login-section {
  width: 40%;
  text-align: right;
  color: var(--dark_black) !important;
  font-weight: 600;
}

.download img {
  max-width: 80%;
}

.login-section button.btn.btn-sm.dropdown-toggle {
  color: var(--dark_black);
  font-weight: 600;
}

/* slider */

.slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 90vh;
  z-index: 1;
}

.slideshow .slideshow-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slideshow .slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slideshow .slide {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slide.is-active {
  display: block;
}

.slide.is-loaded {
  opacity: 1;
}

.slide .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  z-index: 1;
  background-size: cover;
  image-rendering: optimizeQuality;
}

.slide .image-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.image {
  width: 100%;
  object-fit: cover;
  height: 85vh;
}

.slide-content {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: var(--white);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content .title {
  font-size: 50px;
  letter-spacing: 0.35em;
  text-transform: uppercase;
}

.slide-content .text {
  margin: 0 auto;
  max-width: 1000px;
  font-size: 18px;
  line-height: 1.4;
}

.slide-content .text p {
  font-size: 30px;
  color: var(--white);
  font-family: var(--text_secondry);
}

.slide-content .btn,
.notify-btn a.btn,
.activity-btn a {
  color: var(--white);
  margin: 15px 0 0;
  text-transform: uppercase;
  padding: 10px 20px;
  box-shadow: 0px 10px 20px #00000070;
  background: #49a85c;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #49a85c 0%, #d6f433 21%, #853af0 45%, #f07cdd 78%, #00ffe4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #49a85c 0%, #d6f433 21%, #853af0 45%, #f07cdd 78%, #00ffe4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #49a85c 0%, #d6f433 21%, #853af0 45%, #f07cdd 78%, #00ffe4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#49a85c', endColorstr='#00ffe4', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  transition: 2s all ease-in-out;
}

.slide-content .btn:hover,
.notify-btn a.btn:hover,
.activity-btn a:hover {
  transition: 5s all ease-in-out;
  background: -moz-linear-gradient(45deg, #d6f433 0%, #853af0 21%, #f07cdd 45%, #00ffe4 78%, #49a85c 100%);
  background: -webkit-linear-gradient(45deg, #d6f433 0%, #853af0 21%, #f07cdd 45%, #00ffe4 78%, #49a85c 100%);
  background: linear-gradient(45deg, #d6f433 0%, #853af0 21%, #f07cdd 45%, #00ffe4 78%, #49a85c 100%);
}

.slide-content .btn span {
  color: var(--white) !important;
}

.slide-content .btn::before {
  background: var(--white);
}

.pagination {
  position: absolute;

  left: 50%;
  width: 100%;
  height: 12px;
  cursor: default;
  z-index: 2;
  transform: translate(-50px, 10%);
  text-align: center;
}

.pagination .item {
  display: inline-block;
  padding: 15px 5px;
  position: relative;
  width: 22px;
  height: 32px;
  cursor: pointer;
  text-indent: -999em;
  z-index: 1;
}

.pagination .item+.page {
  margin-left: -2px;
}

.pagination .item::before {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  transition: background 0.2s ease;
}

.pagination .item::after {
  width: 0;
  background: var(--white);
  z-index: 2;
  transition: width 0.2s ease;
}

.pagination .item:hover::before,
.pagination .item.is-active::before {
  background-color: var(--dark_yellow);
  width: 15px;
  height: 15px;
  top: 13px;
}


/* tab section */
.service-tab .tab {
  overflow: hidden;
  background-color: var(--dark_yellow);
  height: 82px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.service-tab .tab button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 0px;
  transition: 0.3s;
  font-size: 15px;
  width: 14%;
}

.service-tab .tab button:hover {
  background-color: var(--white);
}

.service-tab .tab button.active {
  background-color: var(--white);
  color: #9f1e1e;
}

.service-tab .tabcontent {
  /* display: none; */
  padding: 6px 12px;
  /* border: 1px solid #ccc; */
  border-top: none;
}

.service-tab .tabcontent {
  animation: fadeEffect 1s;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

main.main-content {
  position: relative;
}

.service-tab {
  position: absolute;
  background: var(--white);
  bottom: 6px;
  z-index: 99;
  border-bottom: 5px solid var(--dark_yellow);
  left: 50%;
  transform: translate(-50%, 6px);
  width: 1244px;
  height: 274px;
}

.service-tab .dropdown-toggle::after {
  border-top: 0.3em solid var(--dark_red);
  margin-left: 3.255em;
}

.service-tab i {
  color: #a7a7a7;
  margin-right: 10px;
}

.service-tab .col-lg-4 {
  text-align: center;
}

.service-tab .flight-section .form-control {
  border-bottom: 1px solid #9e9e9e !important;
  border-radius: 0 !important;
  border: none;
}

.service-tab .form-control:focus {
  box-shadow: none;
}

.form-floating .fa-exchange:before {
  content: "\f0ec";
  color: var(--dark_red);
  border: 1px solid #a74242;
  border-radius: 50%;
  padding: 5px;
}

/* search */
.form-section {
  position: absolute;
  left: 5%;
  bottom: -20px;
}

.form-section form {
  position: relative;
  top: 50%;
  left: 0%;
  transform: translate(-0%, -1%);
  transition: all 1s;
  width: 50px;
  height: 50px;
  background: var(--white);
  box-sizing: border-box;
  border-radius: 10px;
  border: 4px solid var(--white);
  padding: 5px;
  z-index: 999;
  box-shadow: 0px 10px 20px #00000030;
}

.form-section input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
}

.form-section .fa {
  box-sizing: border-box;
  padding: 10px;
  width: 42.5px;
  height: 42.5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: var(--dark_red);
  text-align: center;
  font-size: 1.2em;
  transition: all 1s;
}

.form-section form:hover {
  width: 300px;
  cursor: pointer;
}

.form-section form:hover input {
  display: block;
}

.form-section form:hover .fa {
  background: var(--dark_yellow);
  color: var(--white);
}

@media(max-width:1366px) {
  .service-tab {
    width: 1024px;
  }
}

.room {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

/* .room p {
  font-size:12px;
} */

.room-dp {
  width: 260px;
}

.room .qty i {
  color: var(--dark_red);
  font-size: 20px;
}

.notify-btn .form-floating>.form-control {
  height: calc(2.6rem + 2px);
  background: #dcd7d7;
  border: 0;
  font-style: italic;
}

/* .notify-btn input#floatingInput:placeholder {} */

.notify-btn .form-floating {
  width: 30%;
}

.form-control {
  font-size: 14px;
}

div#Home-Stays {
  background: url('../img/homestay.png') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

#Trains {
  background: url('../img/trains-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

#Buses {
  background: url('../img/buses-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

#Cabs {
  background: url('../img/cab-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

/* footer */
section.footer {
  background: #ebebeb;

  padding: 50px 0;
}

section.footer h6 {
  font-family: var(--text_primary);
  font-size: 14px;
  font-weight: 600;
}

section.footer ul {
  padding: 0;
}

section.footer a {
  font-size: 12px;
}

section.footer .footer-list.text-end a {
  font-size: 13px;
  font-weight: 600;
  line-height: 30px;
}

section.footer a:hover {
  color: var(--dark_red);
}

.fot-bottom {
  background: var(--dark_yellow);
}

.fot-bottom .background_log {
  align-self: center;
}

.fot-bottom .background_ig {
  background: #ebebeb;
}

.fot-bottom small.text-muted {
  font-size: 13px;
}

.fot-bottom li {
  padding: 0 5px;
}

.fot-bottom i {
  border: 1px solid var(--dark_black);
  font-size: 20px;
  border-radius: 50%;
  padding: 7px 9px;
}

.fot-bottom i.fa.fa-facebook {
  padding: 7px 12px;
}

.fot-bottom i:hover {
  color: var(--white);
  border-color: var(--white);
  box-shadow: 0px 10px 20px #0006;
  transition: 1s all ease;
}

.fot-bottom i.fa.fa-facebook:hover {
  background: #0844b8;
}

.fot-bottom i.fa.fa-youtube-play:hover,
.fot-bottom i.fa.fa-pinterest-p:hover {
  background: #f70000;
}

.fot-bottom i.fa.fa-linkedin:hover {
  background: #0270ad;
}

.fot-bottom i.fa.fa-instagram:hover {
  color: var(--white);
  background: #febc50;
  background: -moz-linear-gradient(45deg, #febc50 0%, #f75344 32%, #cd4794 68%, #525dcb 100%);
  background: -webkit-linear-gradient(45deg, #febc50 0%, #f75344 32%, #cd4794 68%, #525dcb 100%);
  background: linear-gradient(45deg, #febc50 0%, #f75344 32%, #cd4794 68%, #525dcb 100%);
}

.fot-bottom i.fa.fa-twitter:hover {
  background: #1c9cea;
}

/* hot deals */
section.hot-deals .section-title h2 {
  font-family: var(--sp_text) !important;
  font-size: 60px;
}

.section-title h2 {
  color: #444;
  font-size: 40px;
}

section.hot-deals {
  background: url('../img/waves.png') no-repeat;
  background-size: contain;
  background-position: left;
  padding: 50px 0;
}

.hot-deal-slider .img-box,
.hot-deal-slider .col-md-3.col-sm-6.col-xs-12 {
  position: relative;
}

.deals-detail.text-center {
  position: absolute;
  bottom: 0;
  left: 48%;
  z-index: 99;
  color: var(--white);
  transform: translate(-50%, -20%);
}

.deals-detail p,
.deals-detail small {
  color: var(--white);
}

.deals-detail h4 {
  margin-top: 10px;
}

.hot-deal-slider .img-box,
.activity-offer {
  overflow: hidden;
  cursor: pointer;
}

.hot-deal-slider .img-box::before,
.activity-offer:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hot-deal-slider .img-box:hover::before,
.activity-offer:hover:before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/* End of Deal */
/* explore activity */
section.explore-activity {
  margin: 50px 0;
}

.activity-detail {
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(10%, -50%);
  z-index: 99;
}

.activity-offer {
  background: url(../img/activity-offer.png) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  border-radius: 20px;
}

.list-detail.text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-detail p {
  color: var(--white);
}

.activity-detail p.m-0.fs-1 {
  color: #ff6f00;
  font-weight: 600;
}

.view-all-btn a.btn-primary {

  border-radius: 5px;
  padding: 10px 100px;
  font-weight: 100;
}

.list-box:after,
.activity-offer:after,
.hot-deal-slider .img-box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #00000042;
  width: 100%;
  border-radius: 50px;
  height: 100%;
}

.list-box,
section.offer .img-box {
  cursor: pointer;
  overflow: hidden;
  border-radius: 20px;
}

.list-box img,
section.offer .img-box {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}

.list-box:hover img,
section.offer .img-box:hover img {
  -webkit-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

/* end of activity */

/* packages */
section.packages {
  background: #e8ebed;
  padding: 10px 0;
  position: relative;
}

section.packages:before {
  content: '';
  position: absolute;
  width: 500px;
  height: 100%;
  background: #cad2d6;
  top: 0;
  right: 10%;
}

.btn-outline-primary {
  color: var(--dark_red) !important;
  border-color: var(--dark_red) !important;
}

.btn-outline-primary:hover {
  color: var(--white) !important;
  background-color: var(--dark_red) !important;
  border-color: var(--dark_red) !important;
}

i.fa.fa-star {
  color: var(--dark_yellow);
}

.thailand,
.japan,
.singapore {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.thailand:before,
.japan:before,
.singapore:before {
  content: '';
  position: absolute;

}

.thailand:before {
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 25px solid var(--white);
  left: -45px;
  top: 14%;
}

.singapore:before {
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid var(--white);
  border-right: 25px solid transparent;
  left: 10%;
  top: -42px;
}

.japan:before {
  border-left: 20px solid var(--white);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 25px solid transparent;
  right: -45px;
  top: 14%;
}

.booknow-btn button {
  padding: 5px 70px;
  box-shadow: 0px 10px 20px #0000001c;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(167 67 67 / 29%) !important;
}

section.packages .h1 {
  color: var(--dark_black);
}

/* End OF packages */

/* client slider */
.our-partner {
  align-items: center;
  justify-content: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  background: #dbf4ff;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slider .slide-track {
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  padding: 40px 0;
  width: 250px;
}

/* end of client */

/* Amazing Offers */
section.offer {
  background: #fee1b0;
  padding: 20px 0 50px 0;
  position: relative;
  margin: 100px 0 150px 0px;
}

section.offer:before {
  position: absolute;
  content: '';
  width: 55%;
  height: 120%;
  background: #fbd79a;
  right: 0;
  top: -10%;
  box-shadow: 0px 10px 20px 2px #00000026;
}

section.offer .swiper {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  position: relative;
}

section.offer .swiper-slide {
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  /* display: -webkit-flex; */
  /* display: flex; */
  /* -webkit-box-pack: center; */
  /* -ms-flex-pack: center; */
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  /* -webkit-box-align: center; */
  /* -ms-flex-align: center; */
  /* -webkit-align-items: center; */
  /* align-items: center; */
  /* position: relative; */
}

/* section.offer .swiper-slide {
  margin-right: 20px !important;
  width: 170px !important;
} */

section.offer .swiper-slide:nth-child(odd) {
  position: relative;
  top: -30px;
  z-index: 999;
}

section.offer .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.offer .offer-btn a {
  font-size: 12px;
  color: var(--white);
}

section.offer .offer-btn a:hover {
  color: var(--dark_yellow);
}

section.offer .location-nm {
  position: relative;
  top: -75px;
}

.swiper-button-next,
.swiper-button-prev {
  background: #49a85c;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #49a85c 0%, #d6f433 21%, #853af0 45%, #f07cdd 78%, #00ffe4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #49a85c 0%, #d6f433 21%, #853af0 45%, #f07cdd 78%, #00ffe4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #49a85c 0%, #d6f433 21%, #853af0 45%, #f07cdd 78%, #00ffe4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#49a85c', endColorstr='#00ffe4', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  transition: 2s all ease-in-out;
  width: 45px !important;
  border-radius: 5px !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 22px !important;
  color: var(--white);
  font-weight: 600;
}

section.offer .img-box {
  position: relative;
}

section.offer .img-box:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0006;
  border-radius: 20px;
  top: 0;
  left: 0;
}

/* End Amazing Offers */
/* blog */

section.blog-post {
  margin-bottom: 50px;
}

section.blog-post .swiper.blog {
  width: 100%;
  padding-bottom: 10px;
}

section.blog-post .swiper.blog .swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 400px; */
  height: 300px;
  position: relative;

}

section.blog-post .swiper.blog .swiper-slide img {
  display: block;
  width: 100%;
}

.blo-img {
  position: relative;
}

.blog-detail {
  position: absolute;
  bottom: 0;
  padding: 0 20px;
}

.blog-detail p {
  color: var(--white);
}

section.blog-post span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--dark_yellow);
}

section.blog-post span.swiper-pagination-bullet {
  background: var(--dark_red);
  opacity: 1;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
}

.pagenation_slider {
  margin-top: 2rem !important;
  top: 0 !important;
  bottom: auto !important;
}

.caption_set {
  bottom: auto !important;
  top: 20rem !important;
}

.pagenation_slider button {
  border-radius: 100% !important;
  width: 11px !important;
  height: 11px !important;
}

.pagenation_slider .active {
  background-color: var(--dark_yellow) !important;
}

.is-sticky {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: var(--white) !important;
  transition: all 1s ease-in-out;
  transform-origin: top;
  transition-delay: .5s;
  animation: fadeHeader 2.0s;
}

@keyframes fadeHeader {
  from {
    opacity: 0;
    transform: translateY(-100%)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.carousel-item.active .title {
  animation: fadeIn 1.5s;
}

.carousel-item.active .text {
  animation: fadeIn 2s;
}

.carousel-item.active .btn {
  animation: fadeIn 2.5s;
}

.ani.swiper-slide-active {
  animation: fadeIn 2.5s;
}

/* .animation_effect{
  animation: fadeIn 2.0s;
} */
.sliderup.active {
  animation: fadeIn 2.0s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.details_section {
  background-color: var(--white);
  /* position:absolute; */
  color: var(--dark_black);
  /* top:100%; */
  display: flex;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
  transform: translate(0%, 10%);
  max-width: 100%;
}

.services_button:hover {
  background-color: var(--dark_yellow);
  border-radius: 3px;

}

.services_button a:hover {
  color: var(--dark_black) !important;
}

.footer_text_AboutUs {
  font-family: var(--text_footer);
  font-size: medium;
  font-weight: 600;
  text-decoration: underline;
  line-height: 1.3;
}

.footer_text {
  font-family: var(--text_footer);
  font-size: small;
  text-align: justify;
  line-height: 1.3;
}

/* FAQ css start */

.faq_tab .btn {
  width: 93px;
}

.faq_tab button {
  background-color: #a74343;
  color: #fff;
  border: 1px solid #a74343;
}

.faq_tab button:hover {
  background-color: #fff !important;
  color: #a74343;
}
.faq_tab .active {
  background-color: #a17272 !important;
  color: #fff;
  border: 1px solid #a17272;
}

.faq_tab .active:hover {
  background-color: #a17272 !important;
  color: #fff;
}

.faq_tab ul li button.active {
  /* background-color: #a74343 !important; */

  /* background-color: #a17272 !important;
  border-color: #a17272;
  color: #fff !important; */
}

.faq_tab ul li button.active:hover {
  /* background-color: #a17272 !important;
  color: #fff !important; */
}

.faq_tab ul li button {
  /* background-color: #e9ecef !important;
  color: #a74343; */

  /* background-color: #a74343 !important;
  color: #fff;
  border: 1px solid #a74343; */
}

.faq_tab ul li button:hover {
  /* color: #e68a8a; */

  /* background-color: #fff !important;
  color: #a74343; */
}

/* FAQ css end */